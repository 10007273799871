/*
 * JXTX Foundation
 * https://www.jxtxfoundation.org
 *
 * JXTX 404 component.
 */

// Core dependencies
import * as React from "react";

function NotFoundPage() {
  return (
    <main>
      <h1>Page Not Found</h1>
    </main>
  );
}

export default NotFoundPage;
